window.addEventListener('scroll', function () {
  const scroll = document.documentElement.scrollTop;
  const scrolltopButton = document.getElementsByClassName('scrolltop')[0];

  if (!scrolltopButton) return

  if (scroll > 2000) {
    scrolltopButton.classList.add('scrolltop--active');
  } else {
    scrolltopButton.classList.remove('scrolltop--active');
  }

  scrolltopButton.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });
});

window.showFlashMessage = function(message, type='notice') {
  let flashMessage = document.getElementById('flashMessage')
  let flashMessageContent = document.createElement('div')

  if (['alert', 'error'].includes(type)) flashMessageContent.classList.add('flash-message-content--alert')
  flashMessageContent.classList.add('flash-message-content')
  flashMessageContent.innerText = message
  flashMessage.appendChild(flashMessageContent)

  setTimeout(() => {
    flashMessageContent.classList.add('flash-message-fade-out')
  }, 5000)
}

window.copyCode = function(e) {
  let code = e.closest('.code-block').querySelector('.rouge-code')

  navigator.clipboard.writeText(code.innerText)
      .then(() => e.innerText = 'Copied')

  window.getSelection().selectAllChildren(code)
}
